import React, { useMemo, useState } from 'react';
import { Row } from '@tanstack/react-table';
import classNames from 'classnames';
import Checkbox from 'components/ui-components-v2/Checkbox';
import DropDownIconButton from 'components/bricks/components/shared/components/drop-down-icon';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import { BrickTree } from 'components/bricks/types/brick.type';
import TreeViewActions from './actions';
import TreeViewVerticalLine from './vertical-line';
import { DotIndicatorSeverity, TooltipValidationResults } from '../../dot-indicator';
import DisabledOverlay from './disabled-overlay';
import TooltipContent from '../../ad-setup/components/alert-tooltip-content';
import { DEFAULT_INDENTATION } from '../data/indentations';

import '../styles/tree-view-item.scss';

interface Props {
    item: Row<BrickTree>;
    previewItemId?: string;
    icon?: React.ReactNode;
    title?: string;
    severity?: DotIndicatorSeverity;
    isDisabled?: boolean;
    tooltip?: TooltipValidationResults;
    onPreviewChange?: (event: React.MouseEvent, previewItemId: string) => void;
    onItemClick?: (event: React.MouseEvent, item: Row<BrickTree>) => void;
}

/**
 * This component is responsible for rendering the tree view list item.
 */
const TreeViewListItem: React.FC<Props> = ({ item, previewItemId, icon, title, severity, isDisabled, tooltip, onPreviewChange, onItemClick }) => {
    const hasChildren = item.subRows && item.subRows.length > 0;
    const indentation = useMemo(() => item.depth * DEFAULT_INDENTATION, [item.depth]);
    const [isHovered, setIsHovered] = useState(false);

    const getIcon = () => {
        if (typeof icon === 'string') {
            return (
                <DisabledOverlay isDisabled={isDisabled}>
                    <Icon>{icon}</Icon>;
                </DisabledOverlay>
            );
        }

        if (React.isValidElement(icon)) {
            return <DisabledOverlay isDisabled={isDisabled}>{icon}</DisabledOverlay>;
        }

        return null;
    };

    const customIcon = useMemo(() => getIcon(), [icon]);

    return (
        <Tooltip placement="right-start" title={tooltip && <TooltipContent errors={tooltip?.errors} />} disableHoverListener={severity !== 'error'}>
            <div
                onClick={(event) => onItemClick && onItemClick(event, item)}
                className={classNames('tree-view-item__content')}
                style={{ paddingLeft: indentation }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}>
                <TreeViewVerticalLine level={item.depth} />
                <Checkbox
                    className="tree-view-item__content__checkbox"
                    disabled={isDisabled}
                    checked={item.getIsSelected()}
                    onChange={(_, value) => item.toggleSelected(value)}
                />
                <div className={'tree-view-item__content__expand-icon'}>
                    {hasChildren && (
                        <DropDownIconButton
                            isIconDown={item.getIsExpanded()}
                            onClick={(event) => {
                                event.stopPropagation();
                                item.getToggleExpandedHandler()();
                            }}
                        />
                    )}
                </div>
                {customIcon}
                <Tooltip title={title}>
                    <div className={classNames('tree-view-item__content__text', { 'tree-view-item__content__text-disabled': isDisabled })}>{title}</div>
                </Tooltip>
                <TreeViewActions
                    item={item}
                    previewItemId={previewItemId}
                    onPreviewChange={onPreviewChange}
                    showPreviewIcon={isHovered}
                    className="tree-view-item__content__actions"
                    severity={severity}
                    disableTooltipHover={severity === 'error'} // The errors are already displayed in the row tooltip
                    tooltip={tooltip}
                />
            </div>
        </Tooltip>
    );
};

export default TreeViewListItem;
